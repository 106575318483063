.characters {
  height: 7rem;
}

.character {
  line-height: 7rem;
  font-size: 3rem;
  font-weight: 700;
  color: #0e067d;
  background-color: #e8f1f8;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 2%;
  text-align: center;
  transition: 200ms ease;
}

.character-error {
  line-height: 7rem;
  font-size: 3rem;
  font-weight: 700;
  color: #0e067d;
  background-color: #e8f1f8;
  border: 1px solid red;
  border-radius: 8px;
  margin: 0 2%;
  text-align: center;
  transition: 200ms ease;
}

.character-error:hover {
  transform: scale(1.05) translateY(-3px);
  transition: 200ms ease;
}

.character-error:first-child {
  margin: 0;
  margin-right: 2%;
}

.character-error:last-child {
  margin: 0;
  margin-left: 2%;
}

.character:hover {
  transform: scale(1.05) translateY(-3px);
  transition: 200ms ease;
}

.character:first-child {
  margin: 0;
  margin-right: 2%;
}

.character:last-child {
  margin: 0;
  margin-left: 2%;
}

.character--inactive {
  background-color: #e8f1f8;
}

.character--selected {
  transform: scale(1.05) translateY(-3px);
  box-shadow: 0px 5px 8px -2px #0e067d22;
  border: 1px solid #0e067d;
  transition: 200ms ease;
}
